import React from "react"
import "./FestTitles.scss"
import "./12FestHero.scss"
import { langVersion, isBrowser } from "../../accessors"

const Fest12Hero = () => {
  return (
    <section id="fest12-hero" className="fest__hero">
      <article className="fest__jumbotron">
        <article className="fest__hero-box fest__hero-box--left">
          <>
            <h3 className="fest__article-title--old">
              {isBrowser()
                ? langVersion(
                    "12. Festiwal Literatury i Teatru",
                    "12. Festival of Literature and Theatre"
                  )
                : "Content is loading..."}
            </h3>
            <h1 className="fest__section-title--old">
              {langVersion("Between.Pomiędzy", "Between.Pomiędzy")}
            </h1>
            <div className="fest__hero-buttons-group">
              {isBrowser() ? (
                <a
                  className="fest__hero-button"
                  role="button"
                  href={langVersion(
                    "/docs/festivals/12-festival/12.Between.Pomiedzy_linki_transmisje.pdf",
                    "/docs/festivals/12-festival/ENG_12.Between.Pomiedzy_links_to_events.pdf"
                  )}
                  target="_blank"
                  title={langVersion("Otwórz pdf", "Otwórz pdf")}
                >
                  {langVersion("LINKI DO WYDARZEŃ", "LINKS TO EVENTS")}
                </a>
              ) : (
                "Content is loading..."
              )}
              {isBrowser() ? (
                <a
                  className="fest__hero-button"
                  role="button"
                  href={langVersion(
                    "/docs/festivals/12-festival/12.Between.Pomiedzy_linki_nagrania.pdf",
                    "/docs/festivals/12-festival/LINKS_RECORDINGS_ENG.pdf"
                  )}
                  target="_blank"
                  title={langVersion("Open pdf", "Open pdf")}
                >
                  {langVersion("LINKI DO NAGRAŃ", "LINKS TO RECORDINGS")}
                </a>
              ) : (
                "Content is loading..."
              )}
            </div>
          </>
        </article>
        <article className="fest__hero-box fest__hero-box--right">
          {isBrowser() ? (
            <>
              <p className="fest__article-text">
                {langVersion(
                  "Between.Pomiędzy to festiwal dwujęzyczny. Część materiałów prezentujemy w języku polskim. Część materiałów w języku angielskim. Gdzie to możliwe zapewniamy obie wersje językowe.",
                  "This is a bilingual festival. Some materials are in English. Others in Polish. We’ve tried to provide both versions wherever possible."
                )}
              </p>
              <a
                className="fest__button-link"
                href={langVersion(
                  "/docs/festivals/12-festival/2021_Festiwal_program_CALOSC_02.05.2021_final.pdf",
                  "/docs/festivals/12-festival/2021_Festival_prog_ENG_09.05.21_final.pdf"
                )}
                target="_blank"
                title={langVersion("Idź do programu", "Go to Program section")}
              >
                <button className="fest__button-main">
                  {langVersion("program", "programme")}
                </button>
              </a>
            </>
          ) : (
            <div>Content is loading...</div>
          )}
        </article>
      </article>
    </section>
  )
}

export default Fest12Hero
